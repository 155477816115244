
.identify-index {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 20px;
    .paging-box {
        padding: 20px;
        text-align: center;
    }
    .identify-center-table {
        flex: 1;
        // margin-bottom: 20px;
        overflow: hidden;
    }
    .add-identify-center {
        text-align: end;
        padding-bottom: 20px;
    }
    .add-identify-center-dialog {
        font-size: 16px;
        .input-title {
            margin: 10px 0;
            font-size: 16px;
            text-align: start;
            color: #333;
        }
        .input-title:nth-child(1) {
            margin-top: 0;
        }
        .logo-box {
            position: relative;
            width: 80px;
            height: 80px;
            box-sizing: border-box;
            border: 1px solid #eeeeee;

            img {
                width: 100%;
                height: 100%;
            }

            i {
                line-height: 1;
                font-size: 14px;
                color: #dcdcdc;
                position: absolute;
                right: -20px;
                top: 0;
                cursor: pointer;
            }
        }
        .logo-uploader {
            ::v-deep.el-upload {
                width: 80px;
                height: 80px;
                background: #eeeeee;
                display: flex;
                align-items: center;
                justify-content: center;

                .logo-uploader-icon {
                    font-size: 32px;
                    color: #a0a0a0;
                }
            }
        }
    }
}
.allot-class,
.allot-class-two {
    &.allot-class-two .el-cascader-menu {
        width: 50%;

        &:last-of-type {
            border-right: none;
        }
    }

    &.allot-class .el-cascader-menu {
        flex: 1;
        // width: calc(100% / 3);

        &:nth-child(3) {
            border-right: none;
        }
    }

    .el-cascader-menu {
        border-right: 1px solid #e4e7ed;
    }
}
::v-deep .el-cascader-menu__wrap {
    height: 300px;
    padding-bottom: 20px;
    .el-cascader-menu__list {
        .el-cascader-node {
            &:hover {
                background: #e7f6ef;
            }
        }
    }
}
::v-deep .el-cascader-menu {
    flex: 1;
    min-width: 0;
}
::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
}
// .cascade-width {
//     width: 100% !important;
//     ::v-deep .el-cascader-menu {
//         width: 100% !important;
//     }
// }
