
.img-box {
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    .img {
        width: 100%;
        height: 100%;
    }
}
.switch-box {
    display: flex;
    align-items: center;
}
::v-deep .el-table .cell div {
    display: inline-block;
}
